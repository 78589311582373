<template>
  <div>
    <abk-list-of-employers :list-of-employers="employers"
                           :linkPath="'company_show_path'"
                           :no-records-text="$t(`components.companies.abk_company_list.${companiesType}.no_records`)"
                           :date-label-text="$t('components.companies.abk_company_list.registration_date')"
                           :show-loader="showLoader">
      <template v-slot="{ company }">
        <div class="ml-auto" style="position: relative; bottom: .4rem;">
          <span class="employer-type" v-if="company.employerType">{{ employerType(company.employerType) }}</span>
          <pcg-btn-symbol v-if="env.showEmployerType == 1 && companiesType === 'accepted'"
                          icon-class="pcg-icon-edit-alt"
                          class="mr-0"
                          :tooltip="$t('components.companies.abk_company_list.edit_employer_type')"
                          @click="editEmployerType(company)"/>
          <pcg-btn-symbol v-if="showAccept(company.status)"
                          class="mr-0"
                          variant="success"
                          icon-class="pcg-icon-check-alt"
                          :tooltip="$t('general.accept')"
                          @click="updateCompanyStatus(company.id, 'accept')"/>
          <pcg-btn-symbol v-if="showReject(company.status)"
                          class="mr-0"
                          variant="danger"
                          icon-class="pcg-icon-times"
                          :tooltip="$t('components.companies.abk_company_list.reject')"
                          @click="updateCompanyStatus(company.id, 'reject')"/>
        </div>
      </template>
    </abk-list-of-employers>
    <b-modal ref="employerType"
             :hide-header="true"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="updateEmployerType"
             @hide="employerTypeEsc"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             footer-class="d-flex justify-content-between abk-modal-footer"
             content-class="abk-modal-content"
    >
      <h2>{{ $t('components.companies.abk_company_list.editing_employer_type') }}</h2>
      <pcg-select
          :label="$t('components.companies.abk_company_list.employer_type')"
          :options="employerTypes"
          v-model="editedCompany.employerType"
      />
    </b-modal>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getCompanies"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import AbkListOfEmployers from '../../components/AbkListOfEmployers'
import ApiCompanies from '../../api/companies'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import LoaderDelayMixin from '../../mixins/loader_delay'
import { mapGetters } from 'vuex'
export default {
  name: 'AbkCompanyList',
  components: { AbkListOfEmployers },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  props: {
    companiesType: {
      type: String,
      default: 'forAcceptance',
      validator (companiesType) {
        return ['forAcceptance', 'accepted', 'rejected'].includes(companiesType)
      }
    }
  },
  data () {
    return {
      editedCompany: {
        employerType: null,
        id: null
      },
      employers: [],
      employerTypes: [
        { text: this.$t('components.companies.abk_company_list.employer_types.lite'), value: 'lite' },
        { text: this.$t('components.companies.abk_company_list.employer_types.employer'), value: 'employer' },
        { text: this.$t('components.companies.abk_company_list.employer_types.partner'), value: 'partner' }
      ]
    }
  },
  mounted () {
    this.getCompanies()
  },
  computed: {
    ...mapGetters('environment', ['env'])
  },
  methods: {
    showReject (status) {
      return (this.companiesType === 'accepted' || this.companiesType === 'forAcceptance') && status !== 'recent'
    },
    showAccept (status) {
      return (this.companiesType === 'rejected' || this.companiesType === 'forAcceptance') && status !== 'recent'
    },
    getCompanies () {
      let status = []
      if (this.companiesType === 'accepted') {
        status = ['accepted']
      } else if (this.companiesType === 'rejected') {
        status = ['rejected']
      } else if (this.companiesType === 'forAcceptance') {
        status = ['recent', 'saved']
      }

      ApiCompanies.getCompaniesList({ status: status, page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.employers = response.data.data.map(company => {
            let userName = null
            if (company.attributes.users.length > 0) {
              userName = company.attributes.users[0].name
            }
            return {
              company: {
                id: company.id,
                name: company.attributes.name,
                status: company.attributes.status,
                employerType: company.attributes.employerType,
                employerTypeText: company.attributes.employerTypeText,
                employerTypeLogo: company.attributes.employerTypeLogo,
                employerTypeLogoNegative: company.attributes.employerTypeLogoNegative
              },
              user_id: company.id,
              avatar: company.attributes.logo,
              date: this.formattedDate(company.attributes.createdAt),
              name: userName
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.companies.abk_company_list.error_download_companies'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    updateCompanyStatus (companyId, action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${action}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiCompanies[`${action}Company`](companyId)
            .then(() => {
              this.getCompanies()
            }).catch(() => {
              this.$toastr.e(this.$t('components.companies.abk_company_list.unexpected_error'))
            })
        }
      })
    },
    editEmployerType (company) {
      this.editedCompany = {
        employerType: company.employerType,
        id: company.id
      }
      this.$refs.employerType.show()
    },
    employerTypeEsc () {
      this.editedCompany = {
        employerType: null,
        id: null
      }
      this.$refs.employerType.hide()
    },
    updateEmployerType () {
      ApiCompanies.updateEmployerType(this.editedCompany)
        .then(() => {
          this.getCompanies()
          this.$toastr.s(this.$t('components.companies.abk_company_list.employer_type_success'))
        }).catch(() => {
          this.$toastr.e(this.$t('components.companies.abk_company_list.unexpected_error'))
        })
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    },
    employerType (type) {
      return this.$t(`components.companies.abk_company_list.employer_types.${type}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/vars";
  /deep/ .list-of-employers {
    .name {
      font-weight: 400 !important;
    }
  }
  h2 {
    text-align: center;
    margin-bottom: 2em;
    color: $main-color;
  }

  /deep/ .modal-footer {
    padding-top: 3em;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }
  .employer-type {
    color: $pcg-gray;
    font-weight: 400;
    margin-right: 1rem;
    position: relative;
    bottom: .3rem;
  }
/deep/ .dropdown-menu {
  overflow: auto;
  max-height: 300px;
}
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  h2 {
    color: $main-color;
  }
  .employer-type {
    color: $pcg-gray;
  }
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
