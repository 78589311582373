<template>
  <div class="question-type-choose-container">
    <button type="button"
            class="btn question-type-choose"
            v-for="type in questionTypes"
            :key="type"
            @click="addType(type)">
      <component :is="`abk-survey-${type}-svg`" variant="secondary"></component>
      {{ $t(`components.abk_survey_question_choose.type.${type}`) }}
    </button>
  </div>
</template>

<script>
import AbkSurveyCheckboxSvg from '../icons/AbkSurveyCheckboxSvg'
import AbkSurveySectionSvg from '../icons/AbkSurveySectionSvg'
import AbkSurveyNumberSvg from '../icons/AbkSurveyNumberSvg'
import AbkSurveyRadiobuttonSvg from '../icons/AbkSurveyRadiobuttonSvg'
import AbkSurveySeparatorSvg from '../icons/AbkSurveySeparatorSvg'
import AbkSurveyTextSvg from '../icons/AbkSurveyTextSvg'
export default {
  name: 'AbkSurveyQuestionChoose',
  components: {
    AbkSurveyTextSvg,
    AbkSurveySeparatorSvg,
    AbkSurveyRadiobuttonSvg,
    AbkSurveyNumberSvg,
    AbkSurveySectionSvg,
    AbkSurveyCheckboxSvg
  },
  props: {
    requiresAnswer: Boolean
  },
  data: function () {
    return {
      questionTypes: ['text', 'number', 'checkbox', 'radiobutton', 'separator', 'section']
    }
  },
  methods: {
    async addType (type) {
      const question = {
        questionType: type,
        displayType: (type === 'checkbox' || type === 'radiobutton') ? 'vertical' : null,
        mandatory: this.requiresAnswer,
        title: '',
        sort: 0,
        description: '',
        questionFields: [],
        requirements: [],
        isNew: true,
        edited: false
      }
      if (type === 'text' || type === 'number' || type === 'checkbox' || type === 'radiobutton') {
        question.questionFields.push({
          tmpId: await this.$store.dispatch('tmpIds/generateTmpId'),
          fieldType: type,
          label: null,
          value: null
        })
      } else if (type === 'separator' || type === 'section') {
        question.title = this.$t(`components.abk_survey_question_choose.type.${type}`)
      }

      this.$emit('addQuestion', question)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  .question-type-choose-container {
    display: flex;
    justify-content: space-around;
    background: transparent;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    .question-type-choose {
      font-weight: bolder;
      cursor: pointer;
      color: $main-active-color;
      padding: 15px;
      img {
        padding-right: 8px;
      }
      @media screen and (max-width: $screen-md-max) {
        padding: 15px 5px;
      }
    }
  }
  .cls-1 {
    color: red;
    fill: red;
  }
</style>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars-contrast";

  .contrast{
    .question-type-choose-container {
      .question-type-choose {
        color: $main-active-color;
      }
    }
  }
</style>
