<template>
  <transition name='slide' mode='out-in'>
    <div v-if="!value._destroy">
      <b-row class="form-group">
        <abk-datetime-picker class="col-12 col-lg-4"
                             :label="$t('components.events.abk_event_step_1_form.start_date')"
                             variant="datetime"
                             v-model="value.startDate"
                             :error-text="getError('startDate')"
                             :show-error="errorExist('startDate')"
                             :disabled="isFinished"/>
        <abk-datetime-picker class="col-12 col-lg-4"
                             :label="$t('components.events.abk_event_step_1_form.end_date')"
                             variant="datetime"
                             v-model="value.endDate"
                             :error-text="getError('endDate')"
                             :show-error="errorExist('endDate')"
                             :disabled="isFinished"/>
        <abk-datetime-picker class="col-12 col-lg-4"
                             :label="$t('components.events.abk_event_step_1_form.registration_start')"
                             variant="datetime"
                             v-model="value.registrationStart"
                             :error-text="getError('registrationStart')"
                             :show-error="errorExist('registrationStart')"
                             :disabled="isFinished"/>
      </b-row>
      <b-row class="form-group">
        <abk-datetime-picker class="col-12 col-lg-4"
                             :label="$t('components.events.abk_event_step_1_form.registration_end')"
                             variant="datetime"
                             v-model="value.registrationEnd"
                             :error-text="getError('registrationEnd')"
                             :show-error="errorExist('registrationEnd')"
                             :disabled="isFinished"/>
        <pcg-text-input class="abk-input col-12 col-lg-4"
                        min="1"
                        :label="$t('components.events.abk_event_step_1_form.free_places')"
                        v-model="value.freePlaces"
                        :disabled="isFinished"
                        :show-error="errorExist('freePlaces')"
                        :error-text="getError('freePlaces')"/>
        <pcg-text-input class="abk-input col-12 col-lg-4"
                        min="1"
                        :label="$t('components.events.abk_event_step_1_form.reserve_places')"
                        v-model="value.reservePlaces"
                        :disabled="isFinished"
                        :show-error="errorExist('reservePlaces')"
                        :error-text="getError('reservePlaces')"/>
      </b-row>
      <b-row class="form-group">
        <pcg-text-input class="abk-input col-12"
                        min="1"
                        :label="$t('components.events.abk_event_step_1_form.reminder_topic')"
                        v-model="value.reminderTopic"
                        :disabled="isFinished"
                        :show-error="errorExist('reservePlaces')"
                        :error-text="getError('reservePlaces')"/>
      </b-row>
      <b-row class="form-group">
        <label class="col-12 pcg-input-label">
          {{ $t('components.events.abk_event_step_1_form.reminder_content') }}
        </label>
        <b-col cols="12">
          <tinymce-editor :init="tinymceSettings"
                          :value="value.reminderContent"
                          @input="update('reminderContent', $event)"/>
        </b-col>
      </b-row>
      <b-row class="form-group">
        <div class="col-12 d-flex align-items-center justify-content-end" v-if="!isFinished && subEventCount > 1">
          <span class="pcg-link delete-reminder-btn delete-subevent" @click="deleteSub()">
            ✕ {{ $t('components.events.abk_event_step_1_form.remove') }}
          </span>
        </div>
      </b-row>
      <div class="sub-event-separator">

      </div>
    </div>
  </transition>
</template>

<script>
import AbkDatetimePicker from '../AbkDatetimePicker'
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import TinymceEditor from '@tinymce/tinymce-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AbkSubEventForm',
  components: { AbkDatetimePicker, TinymceEditor },
  props: {
    subEventCount: Number,
    value: Object,
    errors: Object
  },
  data () {
    return {
      tinymceSettings: {
        content_style: 'p { font-size: 12px; margin: 0 0 14px 0 }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 150,
        plugins: 'lists autolink link',
        toolbar: 'bold italic bullist insert link',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  methods: {
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    deleteSub () {
      this.$emit('remove')
    },
    update (key, value) {
      this.value.reminderContent = value
    }
  },
  watch: {
    'value.startDate': {
      deep: true,
      handler () {
        if (!this.value.registrationEnd) {
          this.value.registrationEnd = this.value.startDate
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['roles']),
    showEmployersSelect () {
      return this.isCareerOffice && !this.employerId
    },
    isCareerOffice () {
      return this.$store.getters['auth/currentRole'] === 'career_office' || this.$store.getters['auth/currentRole'] === 'counselor'
    },
    showEmployerActions () {
      return this.roles && (this.roles.includes('employer') || this.roles.includes('recruiter'))
    },
    showCreateFromTemplate () {
      return this.showEmployerActions && this.eventsCount > 0
    },
    isFinished () {
      return this.value.status === 'finished' || this.value.status === 'canceled'
    },
    isDestroyed () {
      return this._destroy
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .form-container {
    width: 100%;
    padding: 1.785rem 0 $pcg-m-xl;
  }
  .pcg-link {
    cursor: pointer;
  }
  .separator {
    border-bottom: 1px solid $pcg-lightest-gray;
  }
  /deep/ .abk-input {
    margin-bottom: $pcg-m-lg;
    height: fit-content!important;
  }
  .date-input {
    /deep/ input {
      color: $pcg-gray;
    }
    /deep/ .bootstrap-datetimepicker-widget {
      width: 312px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .date-input {
      /deep/ input {
        width: 100%;
      }
      /deep/ .pcg-datetimepicker-btn {
        position: absolute;
      }
    }
    .end-date-picker {
      margin-left: 76px;
    }
  }

  /deep/ .abk-input {
    margin-bottom: $pcg-m-lg;
    height: fit-content!important;
  }

  @media (max-width: $screen-lg-min) {
    .start-date-picker {
      margin-bottom: $pcg-m-lg!important;
    }
  }

  .delete-subevent {
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: .5rem;
  }

  .sub-event-separator {
    border-bottom: 2px solid $pcg-light-gray;
    margin-bottom: 1.5rem;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .separator {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    /deep/ .abk-input {
      margin-bottom: $pcg-m-lg;
    }
    .date-input {
      /deep/ input {
        color: $pcg-gray;
      }
    }
    .sub-event-separator {
      border-bottom: 2px solid $pcg-light-gray;
    }
  }
</style>
