<template>
  <pcg-box class="mb-0">
    <label class="pcg-input-label" :class="{ 'pcg-input-label-required': required }">{{ $t('components.abk_new_page_content_form.title') }}</label>
    <tinymce-editor :init="tinymceSettings" :value="value" @input="$emit('input', $event)"/>
    <div v-show="showError" class="pcg-error-messages">{{ errorText }}</div>
  </pcg-box>
</template>

<script>
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
  name: 'AbkNewPageContentForm',
  components: { TinymceEditor },
  props: {
    value: String,
    showError: {
      type: Boolean,
      default: false
    },
    errorText: String,
    required: Boolean
  },
  data () {
    return {
      tinymceSettings: {
        content_style: 'p { font-size: 12px; margin: 0 0 14px 0 }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: '230px',
        plugins: 'autolink link',
        toolbar: 'bold italic insert link',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .pcg-input-label {
    margin-left: $grid-gutter-width / 2;
  }
</style>
