<template>
  <div class="basic-info-container">
    <b-row>
      <h1 class="pcg-form-title col-12">{{ $t('components.abk_basic_info_form.title') }}</h1>
      <b-col cols="12" xl="6">
        <b-row>
          <b-col cols="12">
            <pcg-text-input autocomplete="on"
                            name="company"
                            :required="true"
                            :label="$t('components.abk_basic_info_form.company_name')"
                            v-model="value.name"
                            :error-text="getError('name')"
                            :show-error="errorExist('name')"/>
          </b-col>
          <b-col cols="12">
            <label class="pcg-input-label">
              {{ $t('components.abk_basic_info_form.brief_description') }}
            </label>
            <tinymce-editor class="m-xl-0"
                            :class="{ invalid: showContentErrors }"
                            :init="tinymceSettings"
                            name="description"
                            :value="value.shortDescription"
                            @input="update('shortDescription', $event)"/>
            <input class="pcg-field d-none" :required="showContentErrors"/>
            <div v-if="errorExist('short_description')" class="pcg-error-messages">{{ getError('short_description') }}</div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="6">
        <b-row>
          <pcg-text-input class="col-12"
                          autocomplete="on"
                          name="address"
                          :label="$t('components.abk_basic_info_form.address')"
                          v-model="value.address"
                          :error-text="getError('address')"
                          :show-error="errorExist('address')"/>
          <pcg-text-input class="col-12"
                          autocomplete="on"
                          name="city"
                          :label="$t('components.abk_basic_info_form.city')"
                          v-model="value.city"
                          :error-text="getError('city')"
                          :show-error="errorExist('city')"/>
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="phone"
                          :label="$t('components.abk_basic_info_form.phone')"
                          v-model="value.phone"
                          :error-text="getError('phone')"
                          :show-error="errorExist('phone')"/>
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="email"
                          :label="$t('components.abk_basic_info_form.email')"
                          v-model="value.email"
                          :error-text="getError('email')"
                          :show-error="errorExist('email')"/>
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="website"
                          :label="$t('components.abk_basic_info_form.website')"
                          v-model="value.website"
                          :error-text="getError('website')"
                          :show-error="errorExist('website')"/>
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="facebook"
                          :label="$t('components.abk_basic_info_form.facebook')"
                          v-model="value.facebook"
                          :error-text="getError('facebook')"
                          :show-error="errorExist('facebook')"/>
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="linkedin"
                          :label="$t('components.abk_basic_info_form.linkedin')"
                          v-model="value.linkedin"
                          :error-text="getError('linkedin')"
                          :show-error="errorExist('linkedin')"/>
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="twitter"
                          :label="$t('components.abk_basic_info_form.twitter')"
                          v-model="value.twitter"
                          :error-text="getError('twitter')"
                          :show-error="errorExist('twitter')"/>
          <pcg-text-input class="col-6 m-xl-0"
                          autocomplete="on"
                          name="nip"
                          :required="true"
                          placeholder="PL1464791822"
                          :label="$t('components.abk_basic_info_form.nip')"
                          v-model="value.nip"
                          :error-text="getError('nip')"
                          :show-error="errorExist('nip')"/>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
  name: 'AbkBasicInfoForm',
  components: { TinymceEditor },
  props: {
    progress: Number,
    value: Object,
    errors: Object
  },
  data () {
    return {
      invalidContentValue: null,
      tinymceSettings: {
        content_style: 'p { font-size: 12px; margin: 0 0 14px 0 }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 392,
        plugins: 'lists autolink link',
        toolbar: 'bold italic bullist insert link',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  watch: {
    'errors.shortDescription': function (newError) {
      if (newError) {
        this.invalidContentValue = this.value.shortDescription
      }
    }
  },
  methods: {
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    }
  },
  computed: {
    showContentErrors () {
      return (!this.changedInvalidContentValue && this.errors.shortDescription)
    },
    changedInvalidContentValue () {
      return this.invalidContentValue !== this.value.shortDescription
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .description-textarea {
    /deep/ textarea {
      height: 392px;
    }
  }
  .form-group {
    margin-bottom: $pcg-m-lg;
  }
  .invalid ~ /deep/ .tox-tinymce {
    box-shadow: $invalid-field-box-shadow;
  }
</style>
