import { render, staticRenderFns } from "./AbkMessageForm.vue?vue&type=template&id=4dff35f5&scoped=true&"
import script from "./AbkMessageForm.vue?vue&type=script&lang=js&"
export * from "./AbkMessageForm.vue?vue&type=script&lang=js&"
import style0 from "./AbkMessageForm.vue?vue&type=style&index=0&id=4dff35f5&scoped=true&lang=scss&"
import style1 from "./AbkMessageForm.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dff35f5",
  null
  
)

export default component.exports