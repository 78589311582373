<template>
  <div class="basic-info-container">
    <b-row>
      <b-col cols="12" xl="6" class="progress-bar-container order-xl-last mb-xl-0">
        <div class="invisible" v-if="false">
          <span class="pcg-dark-gray-color">{{ $t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.progress') }}</span>
          <abk-form-progress-bar class="mb-4" :progress-percent="progress"/>
        </div>
      </b-col>
      <b-col cols="12" xl="6" class="order-xl-first">
        <div class="info d-flex pcg-the-darkest-gray-color">
          <i class="pcg-icon-comment-info"></i>
          <div class="ml-3">{{ $t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.info') }}</div>
        </div>
        <h1 class="pcg-form-title">{{ $t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.title') }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="6">
        <b-row>
          <pcg-text-input class="col-12 col-sm-6"
                          autocomplete="on"
                          name="given-name"
                          :required="true"
                          :show-label="true"
                          :label="$t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.firstName')"
                          :error-text="getError('firstName')"
                          :show-error="errorExist('firstName')"
                          :value="value.firstName"
                          @input="updateForm('firstName', $event)"/>
          <pcg-text-input class="col-12 col-sm-6"
                          autocomplete="on"
                          name="family-name"
                          :required="true"
                          :show-label="true"
                          :label="$t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.lastName')"
                          :error-text="getError('lastName')"
                          :show-error="errorExist('lastName')"
                          :value="value.lastName"
                          @input="updateForm('lastName', $event)"/>
          <b-col cols="12">
            <label class="pcg-input-label">
              {{ $t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.introduction') }}
            </label>
            <tinymce-editor class="m-xl-0"
                            :class="{ invalid: showContentErrors }"
                            :init="tinymceSettings"
                            :value="value.introduction"
                            @input="update('introduction', $event)"/>
            <input class="pcg-field d-none" :required="showContentErrors"/>
            <div v-if="errorExist('introduction')" class="pcg-error-messages">{{ getError('introduction') }}</div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="6">
        <b-row>
          <div class="form-group col-12">
            <div class="pcg-input-label file-label">
              {{ $t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.avatar') }}
            </div>
            <abk-file-input @input="handleFile($event)"
                            :file-url="value.avatarUrl"
                            :status="fileUploadStatus()"
                            ref="avatarInput"
                            :error-text="getError('avatar')"
                            v-b-tooltip.hover
                            :title="getError('avatar')"
                            show-remove-button
                            @remove="removeFile"/>
          </div>
          <pcg-text-input class="col-12 col-sm-6"
                          autocomplete="on"
                          name="tel"
                          :show-label="true"
                          :label="$t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.phone')"
                          :error-text="getError('phone')"
                          :show-error="errorExist('phone')"
                          :value="value.phone"
                          @input="updateForm('phone', $event)"/>
          <pcg-text-input class="col-12 col-sm-6"
                          autocomplete="on"
                          name="email"
                          :show-label="true"
                          :required="true"
                          :label="$t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.email')"
                          :error-text="getError('email')"
                          :show-error="errorExist('email')"
                          :value="value.email"
                          @input="updateForm('email', $event)"/>
          <div class="col-12">
            <b-row v-for="(website, index) in value.websites" :key="index">
              <b-col cols="11">
                <pcg-text-input autocomplete="on"
                                :name="`website-${index}`"
                                :show-label="true"
                                :label="$t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.websites')"
                                :error-text="getError('websites')"
                                :show-error="errorExist('websites')"
                                :value="website"
                                @input="updateForm('websites', $event, true, index)"/>
              </b-col>
              <b-col v-if="value.websites && value.websites.length > 1" cols="1" class="d-flex align-items-center">
                <i @click="removeWebsite(index)" class="pcg-icon-cross remove-website"/>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <pcg-btn size="small"
                       :disabled="!canAddWebsite"
                       @click="addWebsite"
                       variant="additional"
                       class="action-btn">
                {{ $t('components.curriculum_vitaes.abk_curriculum_vitaes_basic_info_form.add_website') }}
              </pcg-btn>
            </div>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AbkFormProgressBar from '../AbkFormProgressBar'
import AbkFileInput from '../AbkFileInput'
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
  name: 'AbkCurriculumVitaesBasicInfoForm',
  components: { AbkFileInput, AbkFormProgressBar, TinymceEditor },
  props: {
    progress: Number,
    value: Object,
    errors: Object,
    loading: Boolean,
    canAddWebsite: Boolean
  },
  data () {
    return {
      invalidContentValue: null,
      tinymceSettings: {
        content_style: 'p { font-size: 12px; margin: 0 0 14px 0 }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 252,
        plugins: 'lists autolink link',
        toolbar: 'bold italic bullist insert link',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  watch: {
    'errors.introduction': function (newError) {
      if (newError) {
        this.invalidContentValue = this.value.introduction
      }
    }
  },
  methods: {
    updateForm (key, value, website, index) {
      if (website) {
        this.$emit('updateWebsite', index, value)
      } else {
        this.$emit('input', { ...this.value, [key]: value })
      }
    },
    updateFormObject (object) {
      this.$emit('input', { ...this.value, ...object })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    handleFile (file) {
      if (file.size > 4200000) {
        this.removeFile()
        this.errors.avatar = [this.$t('components.abk_visual_identification_form.max_size')]
      } else {
        if (this.value.removeAvatar) {
          const copy = { ...this.value, avatar: file }
          delete copy.removeAvatar
          this.$emit('input', copy)
        } else {
          this.updateForm('avatar', file)
        }
        if (this.errorExist('avatar')) {
          delete this.errors.avatar
        }
      }
    },
    fileUploadStatus () {
      if (this.loading) {
        return 'loading'
      } else if (this.errorExist('avatar')) {
        return 'fail'
      } else if (this.value.avatar) {
        return 'success'
      }
      return 'upload'
    },
    removeFile () {
      this.$refs.avatarInput.clear()
      delete this.errors.avatar
      this.updateFormObject({
        avatar: null,
        removeAvatar: true
      })
    },
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    addWebsite () {
      this.$emit('addWebsite')
    },
    removeWebsite (index) {
      this.$emit('removeWebsite', index)
    }
  },
  computed: {
    showContentErrors () {
      return (!this.changedInvalidContentValue && this.errors.introduction)
    },
    changedInvalidContentValue () {
      return this.invalidContentValue !== this.value.introduction
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .save-buttons-container {
    position: fixed;
    top: 125px;
    right: 10%;
    z-index: 20;
    width: fit-content;
    margin-bottom: $pcg-m-xxl;
    text-align: right;
  }
  .info {
    margin-bottom: $pcg-m-xxl;
    font-size: $font-size-s;
    i {
      font-size: $font-size-xl;
      margin-top: .15em;
    }
  }
  button {
    padding-left: 50px;
    padding-right: 50px;
  }
  .description-textarea {
    /deep/ textarea {
      height: 258px;
    }
  }
  .form-group {
    margin-bottom: $pcg-m-lg;
  }

  .section-btn {
    background: none;
    border: none;
    color: $main-active-color;
    padding: 0;
    margin: 0;
    outline: none;
    &.small {
      font-size: $font-size-m;
    }
  }
  .invalid ~ /deep/ .tox-tinymce {
    box-shadow: $invalid-field-box-shadow;
  }
  @media(max-width: 1500px) {
    .save-buttons-container {
      right: 5%!important;
    }
  }

  @media(max-width: $screen-lg-max) {
    .save-buttons-container {
      width: 100%;
      top: 0;
      position: relative!important;
    }
  }

  .remove-website {
    color: $main-active-color;
    cursor: pointer;
  }

</style>
<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .section-btn {
      color: $main-active-color;
    }
    .remove-website {
      color: $main-active-color;
    }
  }
</style>
